import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { ReserveDto } from "../../entity/reserve-dto";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import moment from "moment-timezone";
import { confirmReserveDto } from "../../redux/reservationSlice";
import { useNavigate } from "react-router-dom";
import PaypalComponenet from "../util/paypalComponent";
import FileUploader from "../util/fileUploader";
import { saveReserveDto } from "../../service/reservationService";
import fpsLogo from "../../fps_qr_code.jpeg";
import paymeCode from "../../image/payme-code.jpeg";

import BookingStepper from "./bookingStepper";
import icon01 from "../../image/payment-method-01.svg";
import logoVisa from "../../image/logo-visa.svg";
import logoMaster from "../../image/logo-master.svg";
import logoAMEX from "../../image/logo-AMEX.svg";
import logoAlipayHK from "../../image/logo-alipayhk.svg";
import logoWechatPayHK from "../../image/logo-Wechatpayhk.svg";
import logoFPS from "../../image/logo-FPS.svg";
import logoPayme from "../../image/logo-Payme.svg";
import "../../scss/pages/booking.scss";
import "../../scss/pages/details-page.scss";
import FareComponent from "./fareComponent";
import SoepayComponenet from "../util/soepayComponent";
import { CAR_TYPE_SEDAN } from "../../entity/carTypeDto";
import { current } from "@reduxjs/toolkit";
import { WAIT_AT_ID_TERMINAL_A_OR_B } from "../../entity/waitAtDto";
import { convertLocalTimeToUTC, convertUTCToLocalTime } from "../util/constant";
import { useTranslation } from "react-i18next";

export default function BookingConfirmation() {
  const { t, i18n } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState("");

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const currentReserveDto = useSelector(
    (state: RootState) => state.reserve.currentReserveDto
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clickSubmitButton = async () => {
    if (!currentReserveDto?.paymentStatus) {
      alert(t("paymentFailed"));
      return;
    }
    setLoading(true);
    const reserveDto = {
      ...currentReserveDto,
      starttime: convertLocalTimeToUTC(currentReserveDto!.starttime),
    };

    const confirmReserve = await dispatch(saveReserveDto(reserveDto!))
      .unwrap()
      .then((reserveDto: ReserveDto) => {
        setLoading(false);
        navigate("/bookingresult/" + reserveDto.id);
      });
  };
  useEffect(() => {
    if (!currentReserveDto) {
      navigate("/booking");
    }
  }, [currentReserveDto]);
  useEffect(() => {
    console.log("paymentMethod:" + paymentMethod);
    if (paymentMethod) {
      const reserveDto = {
        ...currentReserveDto,
        paymentMethod: paymentMethod,
      };
      dispatch(confirmReserveDto(reserveDto));
    }
  }, [paymentMethod]);

  const onUploadReceiptSuccess = (filename: string) => {
    const reserveDto = {
      ...currentReserveDto,
      paymentStatus: true,
      receiptFileName: filename,
    };
    dispatch(confirmReserveDto(reserveDto));
  };

  const onPaypalPaymentSuccess = async (transactionRef: string) => {
    const reserveDto = {
      ...currentReserveDto,
      paymentStatus: true,
      paymentGatewayRef: transactionRef,
      starttime: convertLocalTimeToUTC(currentReserveDto!.starttime),
    };
    setLoading(true);

    dispatch(confirmReserveDto(reserveDto));

    const confirmReserve = await dispatch(saveReserveDto(reserveDto!))
      .unwrap()
      .then((reserveDto: ReserveDto) => {
        setLoading(false);
        navigate("/bookingresult/" + reserveDto.id);
      });
  };

  const redirectToPaymentGateway = (
    orderId: string,
    paymentLinkUrl: string
  ) => {
    sessionStorage.setItem("orderId", orderId);
    sessionStorage.setItem("reserveDto", JSON.stringify(currentReserveDto));
    window.location.href = paymentLinkUrl;
  };

  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{t("confirmBooking")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-new-module">
        <div className="container">
          <BookingStepper step={2} />
          <div className="booking-detail-info">
            <div className="row">
              <div className="col-lg-8">
                <div className="booking-information-main">
                  <div className="booking-information-card flex-fill">
                    <div className="booking-info-head">
                      <h5>{t("trip")}</h5>
                    </div>
                    <div className="booking-info-body">
                      <div className="row">
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("passengerName")}</h6>
                          <p>{currentReserveDto?.passengerName}</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("phoneNo")}</h6>
                          <p>{currentReserveDto?.callPerson}</p>
                        </div>
                        <div className="col-12 col-md-12 mb-4">
                          <h6>{t("email")}</h6>
                          <p>{currentReserveDto?.passengerEmail}</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("date")}</h6>
                          <p>
                            {moment(currentReserveDto?.starttime).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("pickupTime")}</h6>
                          <p>
                            {moment(currentReserveDto?.starttime).format(
                              "HH:mm"
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("pickupPlace")}</h6>
                          <p>{currentReserveDto?.locationFrom}</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("destination")}</h6>
                          <p>{currentReserveDto?.locationTo}</p>
                        </div>
                        {currentReserveDto?.flightDto?.flight_no && (
                          <div className="col-12 mb-4">
                            <h6>{t("flightNo")}</h6>
                            <p>{currentReserveDto?.flightDto?.flight_no}</p>
                          </div>
                        )}
                        <div className="col-12 mb-4">
                          <h6>{t("waitingInReceptionArea")}</h6>
                          {currentReserveDto?.waitAtDto?.id ===
                          WAIT_AT_ID_TERMINAL_A_OR_B ? (
                            <p>{t("yes")}</p>
                          ) : (
                            <p>{t("no")}</p>
                          )}
                        </div>

                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("passengerNum")}</h6>
                          <p>{currentReserveDto?.passengerNum}</p>
                        </div>
                        <div className="col-12 col-md-6 mb-4">
                          <h6>{t("luggNum")}</h6>
                          <p>{currentReserveDto?.luggNum}</p>
                        </div>
                        <div className="col-12 mb-4">
                          <h6>{t("carType")}</h6>
                          <p>
                            {currentReserveDto?.carTypeDto?.id ===
                            CAR_TYPE_SEDAN
                              ? t("fourSeatSedan")
                              : t("sixPassengerMPV")}
                          </p>
                        </div>
                        <div className="col-12 mb-4">
                          <h6>{t("passengerRemark")}</h6>
                          <p>{currentReserveDto?.note}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="booking-information-card payment-info-card">
                    <div className="booking-info-head">
                      <div className="d-flex align-items-center">
                        <span>
                          <i className="bx bx-money"></i>
                        </span>
                        <h5>{t("checkout")}</h5>
                      </div>
                    </div>
                    <div className="booking-info-body">
                      <div className="payment-method-types">
                        <h5>{t("choosePayMethod")}</h5>
                        <ul>
                          <li>
                            <label className="payment_custom_check">
                              <input
                                type="radio"
                                name="paymentMethod"
                                value="soepay"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setPaymentMethod(event.target.value);
                                }}
                              />

                              <span className="payment_checkmark">
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoVisa}')`,
                                    }}
                                    className="payment-logo"
                                  />
                                </span>
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoMaster}')`,
                                    }}
                                    className="payment-logo"
                                  />
                                </span>
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoAlipayHK}')`,
                                    }}
                                    className="payment-logo"
                                  />
                                </span>
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoWechatPayHK}')`,
                                    }}
                                    className="payment-logo"
                                  />
                                </span>
                              </span>
                            </label>
                          </li>
                          <li>
                            <label className="payment_custom_check">
                              <input
                                type="radio"
                                name="paymentMethod"
                                value="fps"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setPaymentMethod(event.target.value);
                                }}
                              />

                              <span className="payment_checkmark">
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoFPS}')`,
                                    }}
                                    className="payment-logo-large"
                                  />
                                </span>
                              </span>
                            </label>
                          </li>
                          <li>
                            <label className="payment_custom_check">
                              <input
                                type="radio"
                                name="paymentMethod"
                                value="banktransfer"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setPaymentMethod(event.target.value);
                                }}
                              />

                              <span className="payment_checkmark">
                                <span className="checked-title">
                                  {t("bankTransfer")}
                                </span>
                              </span>
                            </label>
                          </li>
                          <li>
                            <label className="payment_custom_check">
                              <input
                                type="radio"
                                name="paymentMethod"
                                value="payme"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setPaymentMethod(event.target.value);
                                }}
                              />

                              <span className="payment_checkmark">
                                <span className="checked-title">
                                  <div
                                    style={{
                                      backgroundImage: `url('${logoPayme}')`,
                                    }}
                                    className="payment-logo-large"
                                  />
                                </span>
                              </span>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="payment-method-types payments-cards-types">
                        <div className="add-new-cards">
                          {paymentMethod === "fps" && (
                            <div className="row g-4 text-center align-items-center justify-content-center">
                              <img
                                src={fpsLogo}
                                style={{ maxWidth: "300px" }}
                              />
                              <p>FPS ID: 114402407</p>
                            </div>
                          )}
                          {paymentMethod === "banktransfer" && (
                            <div className="row mb-4">
                              <p>Bank A/C No. 521-278168-001</p>
                              <p>HSBC</p>
                              <p>Bonni Travel & Limousine Services Limited</p>
                            </div>
                          )}
                          {paymentMethod === "payme" && (
                            <div className="row g-4 text-center align-items-center justify-content-center">
                              <img
                                src={paymeCode}
                                style={{ maxWidth: "300px" }}
                              />
                            </div>
                          )}
                          {!!(
                            paymentMethod === "banktransfer" ||
                            paymentMethod === "fps" ||
                            paymentMethod === "payme"
                          ) && (
                            <>
                              <div className="row g-4">
                                <p>{t("paymentRemark")}</p>
                              </div>
                              <div className="row">
                                <FileUploader
                                  label={t("uploadReceipt")}
                                  onUploadSuccess={onUploadReceiptSuccess}
                                />
                              </div>
                            </>
                          )}
                          {paymentMethod === "paypal" && (
                            <div className="row">
                              <PaypalComponenet
                                price={currentReserveDto?.totalFare!}
                                onPaymentSuccess={onPaypalPaymentSuccess}
                              />
                            </div>
                          )}
                          {paymentMethod === "soepay" && (
                            <div className="row">
                              <SoepayComponenet
                                price={currentReserveDto?.totalFare!}
                                redirectToPaymentGateway={
                                  redirectToPaymentGateway
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="booking-info-btns justify-content-end d-none d-md-flex">
                    {paymentMethod != "paypal" && paymentMethod != "soepay" && (
                      <Button
                        onClick={clickSubmitButton}
                        type="button"
                        disabled={loading}
                        className="btn btn-primary w-100"
                      >
                        {loading ? (
                          <Spinner animation="border" variant="warning" />
                        ) : (
                          t("confirmBooking")
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 theiaStickySidebar">
                <div className="booking-sidebar">
                  <FareComponent
                    locationFrom={currentReserveDto?.locationFrom!}
                    locationTo={currentReserveDto?.locationTo!}
                    startTime={currentReserveDto?.starttime!}
                    districtFrom={currentReserveDto?.districtFrom}
                    districtTo={currentReserveDto?.districtTo}
                    orderTypeId={currentReserveDto?.orderTypeDto?.id}
                    carTypeId={currentReserveDto?.carNumDto?.id}
                    luggNum={currentReserveDto?.luggNum}
                    waitAtId={currentReserveDto?.waitAtDto?.id}
                    inFareItems={{
                      fare: currentReserveDto?.fare,
                      midnightFare: currentReserveDto?.midnightFare,
                      tunnelFare: currentReserveDto?.tunnelFare,
                      totalFare: currentReserveDto?.totalFare,
                      mustmpvFare: currentReserveDto?.mustmpvFare,
                      extraFare: currentReserveDto?.extraFare,
                      waitingFare: currentReserveDto?.waitingFare,
                    }}
                  />

                  <div className="booking-info-btns d-flex d-md-none">
                    {paymentMethod != "paypal" && paymentMethod != "soepay" && (
                      <Button
                        onClick={clickSubmitButton}
                        type="button"
                        disabled={loading}
                        className="btn btn-primary w-100"
                      >
                        {loading ? (
                          <Spinner animation="border" variant="warning" />
                        ) : (
                          t("confirmBooking")
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
