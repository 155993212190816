import React, { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import { Row, Col } from "react-bootstrap";
import { ReserveDto } from "../../entity/reserve-dto";
import { useSelector } from "react-redux";
import { RootState, store, useAppDispatch } from "../../redux/store";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { reservationService } from "../../service/reservationService";
import { Form, useLoaderData } from "react-router-dom";
import BookingStepper from "./bookingStepper";
import "../../scss/pages/booking.scss";
import "../../scss/pages/users.scss";
import FareComponent from "./fareComponent";
import { CAR_TYPE_SEDAN } from "../../entity/carTypeDto";
import { WAIT_AT_ID_TERMINAL_A_OR_B } from "../../entity/waitAtDto";
import { convertUTCToLocalTime } from "../util/constant";
import { useTranslation } from "react-i18next";
export async function loader({ params }: { params: any }) {
  console.log("loader loaded");

  const filter: ReserveDto = { id: params.id };
  const currentReserveDto = await store
    .dispatch(reservationService.endpoints.getReserveDto.initiate(filter))
    .unwrap();
  return currentReserveDto;
}

export default function BookingStatus({ from }: { from?: string }) {
  const { t, i18n } = useTranslation();

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentReserveDto = useLoaderData() as ReserveDto;
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalFare, setTotalFare] = useState(0);
  useEffect(() => {
    if (currentReserveDto) {
      switch (currentReserveDto.paymentMethod) {
        case "paypal":
          setPaymentMethod("Paypal");
          break;
        case "soepay":
          setPaymentMethod("Visa/Master Card/Alipay/WeChat Pay/Octopus");
          break;
        case "fps":
          setPaymentMethod("FPS");
          break;
        case "banktransfer":
          setPaymentMethod(t("bankTransfer"));
          break;
        case "payme":
          setPaymentMethod("Payme");
          break;
        default:
          break;
      }
      let tempTotalFare = 0;
      if (currentReserveDto.fare) {
        tempTotalFare += currentReserveDto.fare;
      }
      if (currentReserveDto.tunnelFare) {
        tempTotalFare += currentReserveDto.tunnelFare;
      }
      if (currentReserveDto.midnightFare) {
        tempTotalFare += currentReserveDto.midnightFare;
      }
      if (currentReserveDto.mustmpvFare) {
        tempTotalFare += currentReserveDto.mustmpvFare;
      }
      if (currentReserveDto.extraFare) {
        tempTotalFare += currentReserveDto.extraFare;
      }
      if (currentReserveDto.waitingFare) {
        tempTotalFare += currentReserveDto.waitingFare;
      }
      setTotalFare(tempTotalFare);
    }
  }, [currentReserveDto]);

  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{t("bookingStatus")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-new-module">
        <div className="container">
          {from === "booking" && <BookingStepper step={3} />}
          <div className="booking-card">
            <div className="success-book">
              {from === "booking" && (
                <>
                  <span className="success-icon">
                    <i className="fa-solid fa-check-double"></i>
                  </span>
                  <h5>{t("bookingAccepted")}</h5>
                </>
              )}

              <h5 className="order-no">
                {t("bookingNo")}
                <span>#{currentReserveDto?.id}</span>
              </h5>
            </div>
            <div className="booking-header">
              <div className="booking-img-wrap">
                <div className="book-info">
                  <h6>{t("pickupPlace")}</h6>
                  <p>
                    <i className="feather-map-pin"></i>{" "}
                    {currentReserveDto?.locationFrom}
                  </p>
                </div>
              </div>
              <div className="book-amount">
                <p>{t("serviceFee")}</p>
                <h6>${totalFare}</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-8 d-flex">
                <div className="book-card flex-fill">
                  <div className="book-head">
                    <h6>{t("trip")}</h6>
                  </div>
                  <div className="book-body">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("passengerName")}</h6>
                        <p>{currentReserveDto?.passengerName}</p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("phoneNo")}</h6>
                        <p>{currentReserveDto?.callPerson}</p>
                      </div>
                      <div className="col-12 col-md-12 mb-4">
                        <h6>{t("email")}</h6>
                        <p>{currentReserveDto?.passengerEmail}</p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("date")}</h6>
                        <p>
                          {moment(
                            convertUTCToLocalTime(currentReserveDto?.starttime)
                          ).format("YYYY-MM-DD")}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("pickupTime")}</h6>
                        <p>
                          {moment(
                            convertUTCToLocalTime(currentReserveDto?.starttime)
                          ).format("HH:mm")}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("pickupPlace")}</h6>
                        <p>{currentReserveDto?.locationFrom}</p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("destination")}</h6>
                        <p>{currentReserveDto?.locationTo}</p>
                      </div>
                      {currentReserveDto?.flightDto?.flight_no && (
                        <div className="col-12 mb-4">
                          <h6>{t("flightNo")}</h6>
                          <p>{currentReserveDto?.flightDto?.flight_no}</p>
                        </div>
                      )}
                      <div className="col-12 mb-4">
                        <h6>{t("waitingInReceptionArea")}</h6>
                        {currentReserveDto?.waitAtDto?.id ===
                        WAIT_AT_ID_TERMINAL_A_OR_B ? (
                          <p>{t("yes")}</p>
                        ) : (
                          <p>{t("no")}</p>
                        )}
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("passengerNum")}</h6>
                        <p>{currentReserveDto?.passengerNum}</p>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <h6>{t("luggNum")}</h6>
                        <p>{currentReserveDto?.luggNum}</p>
                      </div>
                      <div className="col-12 mb-4">
                        <h6>{t("carType")}</h6>
                        <p>
                          {currentReserveDto?.carTypeDto?.id === CAR_TYPE_SEDAN
                            ? t("fourSeatSedan")
                            : t("sixPassengerMPV")}
                        </p>
                      </div>
                      <div className="col-12 mb-4">
                        <h6>{t("passengerRemark")}</h6>
                        <p>{currentReserveDto?.note}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 d-flex flex-column">
                <div className="book-card">
                  <div className="book-head">
                    <h6>{t("checkout")}</h6>
                  </div>
                  <div className="book-body">
                    <ul className="location-lists">
                      <li>
                        <h6>{t("paymentMethod")}</h6>
                        <p>{paymentMethod}</p>
                      </li>
                      {paymentMethod === "paypal" && (
                        <li>
                          <h6>{t("paymentGatewayRef")}</h6>
                          <p>
                            <span>#{currentReserveDto?.paymentGatewayRef}</span>
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="flex-fill">
                  <div className="booking-sidebar">
                    <FareComponent
                      locationFrom={currentReserveDto?.locationFrom!}
                      locationTo={currentReserveDto?.locationTo!}
                      startTime={currentReserveDto?.starttime!}
                      districtFrom={currentReserveDto?.districtFrom}
                      districtTo={currentReserveDto?.districtTo}
                      orderTypeId={currentReserveDto?.orderTypeDto?.id}
                      carTypeId={currentReserveDto?.carNumDto?.id}
                      luggNum={currentReserveDto?.luggNum}
                      waitAtId={currentReserveDto?.waitAtDto?.id}
                      inFareItems={{
                        fare: currentReserveDto?.fare,
                        midnightFare: currentReserveDto?.midnightFare,
                        tunnelFare: currentReserveDto?.tunnelFare,
                        mustmpvFare: currentReserveDto?.mustmpvFare,
                        extraFare: currentReserveDto?.extraFare,
                        waitingFare: currentReserveDto?.waitingFare,
                        totalFare: currentReserveDto?.totalFare,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
