import React, { ReactElement, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import BookingPage from "./component/booking/bookingPage";
import AuthComponent from "./component/account/authComponent";
import { Provider, useSelector } from "react-redux";
import { RootState, store } from "./redux/store";
import BookingHistory from "./component/booking/bookingHistory";
import BookingConfirmation from "./component/booking/bookingConfirmation";
import { logout } from "./redux/authSlice";
import BookingStatus, {
  loader as bookingStatusLoader,
} from "./component/booking/bookingStatus";
import App from "./App";
import HomePage from "./component/home";
import { getUserDetails } from "./service/authService";
import { APIProvider } from "@vis.gl/react-google-maps";
import { GOOGLE_MAP_API_KEY } from "./component/util/constant";
import TermsAndCondition from "./component/booking/termsAndCondition";
import BookingRedirectFromPaymentGateway from "./component/booking/bookingRedirectFromPaymentGateway";
import "./i18n";
const ProtectedRoute: React.FC<{
  children: ReactElement<any, any>;
}> = ({ children }) => {
  if (!store.getState().auth.currentUser) {
    store
      .dispatch(getUserDetails())
      .unwrap()
      .then((fulfilled: any) => {
        return children;
      })
      .catch((rejected: any) => {
        return <Navigate to="/login" replace />;
      });
  }

  return children;
};
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense>
        <App />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <Navigate to="/home" replace />
          </Suspense>
        ),
      },

      {
        path: "/login",
        element: (
          <Suspense>
            <AuthComponent page="login" />
          </Suspense>
        ),
      },
      {
        index: true,
        path: "/home",
        element: (
          <Suspense>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: "/booking",
        element: (
          <ProtectedRoute>
            <BookingPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/register",
        element: (
          <Suspense>
            <AuthComponent page="register" />
          </Suspense>
        ),
      },
      {
        path: "/forgotpassword",
        element: (
          <Suspense>
            <AuthComponent page="forgotpassword" />
          </Suspense>
        ),
      },
      {
        path: "/resetpassword",
        element: (
          <Suspense>
            <AuthComponent page="resetpassword" />
          </Suspense>
        ),
      },
      {
        path: "/bookinghistory",
        element: (
          <ProtectedRoute>
            <BookingHistory />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bookingconfirmation",
        element: (
          <ProtectedRoute>
            <BookingConfirmation />
          </ProtectedRoute>
        ),
      },
      {
        path: "/redirectfrompayment",
        element: (
          <ProtectedRoute>
            <BookingRedirectFromPaymentGateway />
          </ProtectedRoute>
        ),
      },
      {
        path: "/bookingresult/:id",
        element: (
          <ProtectedRoute>
            <BookingStatus from="booking" />
          </ProtectedRoute>
        ),
        loader: bookingStatusLoader,
      },
      {
        path: "/bookingstatus/:id",
        element: (
          <ProtectedRoute>
            <BookingStatus />
          </ProtectedRoute>
        ),
        loader: bookingStatusLoader,
      },
      {
        path: "/termsandcondition",
        element: (
          <Suspense>
            <TermsAndCondition />
          </Suspense>
        ),
      },
    ],
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.log("google_map_key: " + GOOGLE_MAP_API_KEY);
store
  .dispatch(getUserDetails())
  .unwrap()
  .then((fulfilled: any) => {})
  .finally(() => {
    root.render(
      <React.StrictMode>
        <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </APIProvider>
      </React.StrictMode>
    );
  });

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if ((!response.ok && response.status === 401) || response.redirected) {
    // 401 error handling
    console.log("session invalid");
    store.dispatch(logout());
    window.location.href = "/";
    return Promise.reject(response);
  }
  return response;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
