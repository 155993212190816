import { Link } from "react-router-dom";
import { ReserveDto } from "../../entity/reserve-dto";
import { useGetReserveDtoListByClientQuery } from "../../service/reservationService";
import Footer from "../footer";
import Header from "../header";
import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";
import { convertUTCToLocalTime } from "../util/constant";
import { useTranslation } from "react-i18next";
export default function BookingHistory() {
  const { t, i18n } = useTranslation();

  const { data, isLoading, error } = useGetReserveDtoListByClientQuery("", {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{t("bookingHistory")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="content-header d-flex align-items-center justify-content-between">
            <h4>{t("bookingHistory")}</h4>
          </div>
          <div className="row">
            <div className="col-lg-12 d-flex">
              <div className="card book-card flex-fill mb-0">
                <div className="card-body">
                  {isLoading ? (
                    <Spinner animation="border" variant="warning" />
                  ) : (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("date")}</th>
                            <th scope="col">{t("pickupTime")}</th>
                            <th scope="col">{t("pickupPlace")}</th>
                            <th scope="col">{t("destination")}</th>
                            <th scope="col">{t("status")}</th>
                            <th scope="col">{t("details")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.map((reserveDto: ReserveDto) => (
                              <tr>
                                <td>
                                  {moment(
                                    convertUTCToLocalTime(reserveDto.starttime)
                                  ).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {moment(
                                    convertUTCToLocalTime(reserveDto.starttime)
                                  ).format("HH:mm")}
                                </td>
                                <td>{reserveDto.locationFrom}</td>
                                <td>{reserveDto.locationTo}</td>
                                <td>{reserveDto.orderStatusDto?.label}</td>
                                <td>
                                  <Link to={`/bookingstatus/${reserveDto.id}`}>
                                    <button className="btn btn-md rounded-circle bg-light border">
                                      <i className="bi bi-eye"></i>
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
