import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function RedirectLoginPage() {
  const { t, i18n } = useTranslation();

  const clickLoginButton = () => {
    const successUrl = encodeURIComponent(
      `${window.location.protocol}//${window.location.host}/home`
    );
    window.location.href = `/auth/login?successUrl=${successUrl}`;
  };
  useEffect(() => {
    window.location.href = `/login/oauth2`;
  }, []);
  return (
    <>
      <h1>{t("memberLogin")}</h1>
      <div className="text-center dont-have">
        <Button
          type="button"
          onClick={clickLoginButton}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          {t("passwordLogin")}
        </Button>
      </div>
      <div className="text-center dont-have">
        {t("notYetAMember")} &nbsp;
        <Link to={`/register`}>{t("joinNow")}</Link>
      </div>
    </>
  );
}
