import { useEffect } from "react";
import Footer from "../footer";
import Header from "../header";
import AOS from "aos";
import { useTranslation } from "react-i18next";

export default function TermsAndCondition() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{t("termsAndCondition")}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-policy">
                <h6 data-aos="fade-down" className="aos pb-4">
                  {t("termsAndConditionPage.bookingProcess.title")}
                </h6>
                <ul data-aos="fade-down" className="aos">
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li1")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li2")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li3")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li4")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li5")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li6")}
                  </li>
                  <li>
                    <span>
                      <i className="fa-solid fa-circle-check"></i>
                    </span>
                    {t("termsAndConditionPage.bookingProcess.li7")}
                  </li>
                </ul>
                <p data-aos="fade-down" className="aos">
                  {t("termsAndConditionPage.bookingProcess.p1")}
                </p>

                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title2")}
                  </span>{" "}
                  {t("termsAndConditionPage.p2")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title3")}
                  </span>{" "}
                  {t("termsAndConditionPage.p3")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title4")}
                  </span>{" "}
                  {t("termsAndConditionPage.p4")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title5")}
                  </span>{" "}
                  {t("termsAndConditionPage.p5")}
                </p>
                <p data-aos="fade-down" className="aos">
                  {t("termsAndConditionPage.p5sub1")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title6")}
                  </span>{" "}
                  {t("termsAndConditionPage.p6")}
                </p>
                <p data-aos="fade-down" className="aos">
                  {t("termsAndConditionPage.p6sub1")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title7")}
                  </span>{" "}
                  {t("termsAndConditionPage.p7")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title8")}
                  </span>{" "}
                  {t("termsAndConditionPage.p8")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title9")}
                  </span>{" "}
                  {t("termsAndConditionPage.p9")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title10")}
                  </span>{" "}
                  {t("termsAndConditionPage.p10")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title11")}
                  </span>{" "}
                  {t("termsAndConditionPage.p11")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title12")}
                  </span>{" "}
                  2{t("termsAndConditionPage.p12")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title13")}
                  </span>{" "}
                  {t("termsAndConditionPage.p13")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title14")}
                  </span>{" "}
                  {t("termsAndConditionPage.p14")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title15")}
                  </span>{" "}
                  {t("termsAndConditionPage.p15")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title16")}
                  </span>{" "}
                  {t("termsAndConditionPage.p16")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title17")}
                  </span>{" "}
                  {t("termsAndConditionPage.p17")}
                </p>
                <p data-aos="fade-down" className="aos">
                  <span className="fw-bolder">
                    {t("termsAndConditionPage.title18")}
                  </span>{" "}
                  {t("termsAndConditionPage.p18")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
