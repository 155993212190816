import { useState } from "react";
import { User } from "../../entity/user";
import { useSaveUserMutation } from "../../service/accountService";
import { useNavigate } from "react-router-dom";
import { Button, Form, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function RegisterPage() {
  const { t, i18n } = useTranslation();

  const [validated, setValidated] = useState(false);
  const [userName, setUserName] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [
    saveUser, // This is the mutation trigger
    { isLoading: isUpdating, error }, // This is the destructured mutation result
  ] = useSaveUserMutation();

  const clickRegisterButton = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    if (pwd !== confirmPwd) {
      setToastMessage(t("invalidConfirmPassword"));
      setShowToast(true);
      return;
    }

    const user: User = {};
    user.userName = userName;
    user.email = userName;
    user.pwd = pwd;
    user.displayName = displayName;
    user.phoneNo = phoneNo;
    const savedUser = saveUser(user)
      .unwrap()
      .then((fulfilled: any) => {
        navigate("/login");
      })
      .catch((rejected: any) => {
        setToastMessage(t("invalidRegisterAccount"));
        setShowToast(true);
      });
  };

  return (
    <>
      <h1>{t("registerAccount")}</h1>

      <Form onSubmit={clickRegisterButton} noValidate validated={validated}>
        <Form.Group className="input-block form-floating" controlId="userName">
          <Form.Control
            type="email"
            placeholder={t("emailAddress")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUserName(event.target.value);
            }}
            required
          />
          <Form.Label>
            {t("emailAddress")} <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group className="input-block form-floating" controlId="pwd">
          <Form.Control
            type="password"
            placeholder={t("password")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            {t("password")} <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group
          className="input-block form-floating"
          controlId="confirmPwd"
        >
          <Form.Control
            type="password"
            placeholder={t("confirmPassword")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmPwd(event.target.value);
            }}
            required
          />
          <Form.Label>
            {t("confirmPassword")} <span className="text-danger">*</span>
          </Form.Label>
        </Form.Group>
        <Form.Group
          className="input-block form-floating"
          controlId="displayName"
        >
          <Form.Control
            type="text"
            placeholder={t("contactName")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDisplayName(event.target.value);
            }}
            required
          />
          <Form.Label>{t("contactName")}</Form.Label>
        </Form.Group>
        <Form.Group className="input-block form-floating" controlId="phoneNo">
          <Form.Control
            type="text"
            placeholder={t("phoneNo")}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneNo(event.target.value);
            }}
            required
          />
          <Form.Label>{t("phoneNo")}</Form.Label>
        </Form.Group>

        <div className="input-block">
          <p>{t("agreeRegister")}</p>
        </div>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
        <Button
          type="submit"
          disabled={isUpdating}
          className="btn btn-outline-light w-100 btn-size mt-1"
        >
          {isUpdating ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            t("registerAccount")
          )}
        </Button>
      </Form>
    </>
  );
}
