import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

import Footer from "../footer";
import Header from "../header";
import { Row, Col, Toast } from "react-bootstrap";
import { ReserveDto } from "../../entity/reserve-dto";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import moment from "moment";
import { confirmReserveDto } from "../../redux/reservationSlice";
import { useNavigate } from "react-router-dom";
import "../../scss/pages/booking.scss";

import BookingStepper from "./bookingStepper";
import {
  TYPE_OF_ARRIVAL_FROM_AIRPORT,
  TYPE_OF_DEPART_TO_AIRPORT,
  TYPE_OF_POINT_TO_POINT,
} from "../../entity/orderType";
import FareComponent from "./fareComponent";
import GoogleMapComponent from "./GoogleMapComponent";
import GoogleMapAutoCompleteTextField from "./GoogleMapAutoCompleteTextField";
import {
  convertLocalTimeToUTC,
  FareType,
  isAtHongKongAirport,
} from "../util/constant";
import { useLazyGetDistrictByReserveDtoQuery } from "../../service/reservationService";
import { District } from "../../entity/district";
import { CAR_TYPE_MPV, CAR_TYPE_SEDAN } from "../../entity/carTypeDto";
import { WAIT_AT_ID_TERMINAL_A_OR_B } from "../../entity/waitAtDto";
import { useTranslation } from "react-i18next";

export default function BookingPage() {
  const { t, i18n } = useTranslation();

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const [key, setKey] = useState(0);
  useEffect(() => {
    console.log("key: " + key);
    setKey((prevKey) => prevKey + 1);
  }, []);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [passengerName, setPassengerName] = useState(currentUser?.displayName);
  const [passengerEmail, setPassengerEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [locationFrom, setLocationFrom] = useState("");
  const locationFromRef = useRef<HTMLInputElement>(null);

  const [displayFlightNo, setDisplayFlightNo] = useState(false);
  const [flightNo, setFlightNo] = useState("");

  const [locationTo, setLocationTo] = useState("");
  const locationToRef = useRef<HTMLInputElement>(null);

  const [passengerNum, setPassengerNum] = useState(1);
  const [fareItems, setFareItems] = useState<FareType>({});
  const [districtFrom, setDistrictFrom] = useState<District>();
  const [districtTo, setDistrictTo] = useState<District>();

  const [luggNum, setLuggNum] = useState(1);
  const [callPerson, setCallPerson] = useState(currentUser?.phoneNo);

  const [validated, setValidated] = useState(false);
  const [duration, setDuration] = useState("");
  const [orderTypeId, setOrderTypeId] = useState<number>();
  const [carTypeId, setCarTypeId] = useState(13);
  const [waitAtId, setWaitAtId] = useState(0);
  const [note, setNote] = useState("");

  const [locationToPlace, setLocationToPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();
  const [locationFromPlace, setLocationFromPlace] = useState<
    google.maps.places.PlaceResult | undefined
  >();

  const currentReserveDto = useSelector(
    (state: RootState) => state.reserve.currentReserveDto
  );
  const [getDistrict, { data: reserveDtoWithDistrict, isLoading, isError }] =
    useLazyGetDistrictByReserveDtoQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onFareChange = (tempFare: FareType) => {
    setFareItems(tempFare);
  };
  const updateLocationFrom = (place: google.maps.places.PlaceResult) => {
    setLocationFrom(place.name!);
    setLocationFromPlace(place);
  };
  const updateLocationTo = (place: google.maps.places.PlaceResult) => {
    setLocationTo(place.name!);
    setLocationToPlace(place);
  };
  useEffect(() => {
    let orderType = TYPE_OF_POINT_TO_POINT;
    if (
      locationFromPlace &&
      (isAtHongKongAirport({
        lat: locationFromPlace?.geometry?.location?.lat()!,
        lng: locationFromPlace?.geometry?.location?.lng()!,
      }) ||
        locationFrom === "機場")
    ) {
      orderType = TYPE_OF_ARRIVAL_FROM_AIRPORT;
    }
    if (
      locationToPlace &&
      (isAtHongKongAirport({
        lat: locationToPlace?.geometry?.location?.lat()!,
        lng: locationToPlace?.geometry?.location?.lng()!,
      }) ||
        locationTo === "機場")
    ) {
      orderType = TYPE_OF_DEPART_TO_AIRPORT;
    }
    setOrderTypeId(orderType);
    if (
      orderType == TYPE_OF_ARRIVAL_FROM_AIRPORT ||
      orderType == TYPE_OF_DEPART_TO_AIRPORT
    ) {
      setDisplayFlightNo(true);
    } else {
      setDisplayFlightNo(false);
      setFlightNo("");
    }
  }, [locationFromPlace, locationToPlace]);

  useEffect(() => {
    if (
      document.activeElement !== locationFromRef.current &&
      document.activeElement !== locationToRef.current
    ) {
      const requestDto: ReserveDto = {
        locationFrom: locationFrom,
        locationTo: locationTo,
      };
      getDistrict(requestDto);
    }
  }, [locationFrom, locationTo]);

  useEffect(() => {
    console.log("update district");
    if (reserveDtoWithDistrict?.districtFrom) {
      console.log(
        "update district districtFrom:" +
          reserveDtoWithDistrict?.districtFrom.id
      );
      setDistrictFrom(reserveDtoWithDistrict?.districtFrom);
    }
    if (reserveDtoWithDistrict?.districtTo) {
      setDistrictTo(reserveDtoWithDistrict?.districtTo);
    }
  }, [reserveDtoWithDistrict]);

  useEffect(() => {
    setPassengerName(currentUser?.displayName!);
  }, [currentUser]);

  useEffect(() => {
    if (currentReserveDto) {
      setLocationFrom(currentReserveDto?.locationFrom!);
      setLocationFromPlace(currentReserveDto?.locationFromPlace!);

      setLocationTo(currentReserveDto?.locationTo!);
      setLocationToPlace(currentReserveDto?.locationToPlace!);

      setPassengerName(currentReserveDto?.passengerName!);
      setStartDate(currentReserveDto?.starttime!);
      setStartTime(currentReserveDto?.starttime!);
    }
  }, [currentReserveDto]);

  useEffect(() => {
    if (passengerNum >= 4 || luggNum >= 4) {
      setCarTypeId(CAR_TYPE_MPV);
    }
  }, [passengerNum, luggNum]);

  const clickSubmitButton = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (form.checkValidity() === false) {
      setToastMessage(t("invalidInput"));
      setShowToast(true);
      return;
    }
    if (
      orderTypeId !== TYPE_OF_ARRIVAL_FROM_AIRPORT &&
      orderTypeId !== TYPE_OF_DEPART_TO_AIRPORT
    ) {
      setToastMessage(t("invalidAirportOnly"));
      setShowToast(true);
      return;
    }
    const reserveDto: ReserveDto = {};
    reserveDto.passengerName = passengerName;
    reserveDto.passengerEmail = currentUser?.email;
    reserveDto.starttime = startDate;

    reserveDto.starttime.setHours(
      startTime.getHours(),
      startTime.getMinutes(),
      0
    );

    reserveDto.locationFrom = locationFrom;
    reserveDto.locationTo = locationTo;
    reserveDto.locationFromPlace = locationFromPlace;
    reserveDto.locationToPlace = locationToPlace;
    if (districtFrom) {
      reserveDto.districtFrom = districtFrom;
    }
    if (districtTo) {
      reserveDto.districtTo = districtTo;
    }
    if (
      isAtHongKongAirport({
        lat: locationFromPlace?.geometry?.location?.lat()!,
        lng: locationFromPlace?.geometry?.location?.lng()!,
      })
    ) {
      reserveDto.orderTypeDto = { id: TYPE_OF_ARRIVAL_FROM_AIRPORT };
    } else if (
      isAtHongKongAirport({
        lat: locationToPlace?.geometry?.location?.lat()!,
        lng: locationToPlace?.geometry?.location?.lng()!,
      })
    ) {
      reserveDto.orderTypeDto = { id: TYPE_OF_DEPART_TO_AIRPORT };
    } else {
      if (orderTypeId) {
        reserveDto.orderTypeDto = { id: orderTypeId };
      } else {
        reserveDto.orderTypeDto = { id: TYPE_OF_POINT_TO_POINT };
      }
    }

    if (flightNo) {
      reserveDto.flightDto = {
        flight_no: flightNo,
        flight_date: reserveDto.starttime,
      };
    }
    reserveDto.passengerNum = passengerNum;
    reserveDto.luggNum = luggNum;
    reserveDto.fare = fareItems.fare;
    reserveDto.tunnelFare = fareItems.tunnelFare;
    reserveDto.midnightFare = fareItems.midnightFare;
    reserveDto.mustmpvFare = fareItems.mustmpvFare;
    reserveDto.extraFare = fareItems.extraFare;
    reserveDto.waitingFare = fareItems.waitingFare;

    reserveDto.totalFare = fareItems.totalFare;

    reserveDto.createUser = "online";
    reserveDto.callPerson = callPerson;
    reserveDto.orderStatusDto = { id: 23 };
    reserveDto.carTypeDto = { id: carTypeId };
    if (waitAtId && waitAtId > 0) {
      reserveDto.waitAtDto = { id: waitAtId };
    }
    reserveDto.clientDto = {
      name: currentUser?.userName,
      phone: currentUser?.phoneNo,
    };
    reserveDto.note = note;
    const confirmReserve = await dispatch(confirmReserveDto(reserveDto));
    navigate("/bookingconfirmation");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      event.key === "Enter" &&
      (event.target as HTMLElement).tagName !== "BUTTON"
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className="main-wrapper">
      <Header />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{t("booknow")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="booking-new-module">
        <div className="container">
          <BookingStepper step={1} />
          <GoogleMapComponent
            from={locationFrom}
            to={locationTo}
            key={key}
            setDuration={setDuration}
          />
          <Form
            noValidate
            validated={validated}
            onSubmit={clickSubmitButton}
            onKeyDown={handleKeyDown}
          >
            <div className="booking-detail-info">
              <div className="row">
                <div className="col-lg-8">
                  <div className="booking-information-main">
                    <div className="booking-information-card booking-type-card">
                      <div className="booking-info-head">
                        <span>
                          <i className="bx bxs-location-plus"></i>
                        </span>
                        <h5>{t("trip")}</h5>
                      </div>
                      <div className="booking-info-body">
                        <Row>
                          <Col>
                            <p>{t("bookingRemark1")}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="locationFrom"
                            >
                              <Form.Label>
                                {t("pickupPlace")}{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder={t("pickupPlace")}
                                value={locationFrom}
                                ref={locationFromRef}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setLocationFrom(event.target.value);
                                }}
                                required
                              />
                              <GoogleMapAutoCompleteTextField
                                textField={locationFromRef.current!}
                                onTextChange={updateLocationFrom}
                                key={key}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="locationTo"
                            >
                              <Form.Label>
                                {t("destination")}{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder={t("destination")}
                                value={locationTo}
                                ref={locationToRef}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setLocationTo(event.target.value);
                                }}
                                required
                              />
                              <GoogleMapAutoCompleteTextField
                                textField={locationToRef.current!}
                                onTextChange={updateLocationTo}
                                key={key}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {duration && (
                          <Row>
                            <Col>
                              <p className="text-primary pb-2">
                                {t("estimateTrafficTime")}&nbsp;
                                {duration}
                              </p>
                            </Col>
                          </Row>
                        )}

                        {displayFlightNo && (
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Group
                                className="input-block"
                                controlId="flight_no"
                              >
                                <Form.Label>{t("flightNo")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={t("flightNo")}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFlightNo(event.target.value);
                                  }}
                                  required={
                                    orderTypeId === TYPE_OF_ARRIVAL_FROM_AIRPORT
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              xs={12}
                              md={6}
                              className="d-flex align-items-end"
                            >
                              <Form.Group className="input-block">
                                <label className="custom_check d-inline-flex location-check">
                                  {t("waitingInReceptionArea")}
                                  <input
                                    type="checkbox"
                                    name="waitAtId"
                                    defaultChecked={
                                      !!(
                                        waitAtId &&
                                        waitAtId == WAIT_AT_ID_TERMINAL_A_OR_B
                                      )
                                    }
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      if (
                                        waitAtId &&
                                        waitAtId === WAIT_AT_ID_TERMINAL_A_OR_B
                                      ) {
                                        setWaitAtId(0);
                                      } else {
                                        setWaitAtId(WAIT_AT_ID_TERMINAL_A_OR_B);
                                      }
                                    }}
                                  ></input>
                                  <span className="checkmark"></span>
                                </label>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="passengerName"
                            >
                              <Form.Label>
                                {t("passengerName")}{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("passengerName")}
                                value={passengerName}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setPassengerName(event.target.value);
                                }}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="callPerson"
                            >
                              <Form.Label>{t("phoneNo")}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("phoneNo")}
                                value={callPerson}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setCallPerson(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="input-block"
                              controlId="passengerEmail"
                            >
                              <Form.Label>{t("email")}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("email")}
                                value={currentUser?.email}
                                readOnly={true}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <div className="input-block">
                              <label htmlFor="startDate" className="form-label">
                                {t("date")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Calendar
                                className="w-100"
                                inputClassName="form-control"
                                inputId="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.value!)}
                              />
                            </div>
                          </Col>
                          <Col xs={12} md={6}>
                            <div className="input-block">
                              <label htmlFor="startTime" className="form-label">
                                {t("pickupTime")}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Calendar
                                className="w-100"
                                inputClassName="form-control"
                                value={startTime}
                                onChange={(e) => setStartTime(e.value!)}
                                timeOnly
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="passengerNum"
                            >
                              <Form.Label>{t("passengerNum")}</Form.Label>
                              <Form.Select
                                className="form-control select"
                                aria-label={t("passengerNum")}
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  setPassengerNum(parseInt(event.target.value));
                                }}
                                value={passengerNum}
                                required
                              >
                                {(() => {
                                  const options = [];
                                  for (let i = 1; i <= 6; i++) {
                                    options.push(
                                      <option key={i} value={i}>
                                        {i}
                                      </option>
                                    );
                                  }
                                  return options;
                                })()}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className="input-block"
                              controlId="luggNum"
                            >
                              <Form.Label>{t("luggNum")}</Form.Label>
                              <Form.Select
                                className="form-control select"
                                aria-label={t("luggNum")}
                                onChange={(
                                  event: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  setLuggNum(parseInt(event.target.value));
                                }}
                                value={luggNum}
                                required
                              >
                                {(() => {
                                  const options = [];
                                  for (let i = 1; i <= 6; i++) {
                                    options.push(
                                      <option key={i} value={i}>
                                        {i}
                                      </option>
                                    );
                                  }
                                  return options;
                                })()}
                              </Form.Select>
                              {!!(luggNum && luggNum > 4) && (
                                <span className="text-danger">
                                  {t("luggRemark")}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Form.Group className="input-block">
                              <Form.Label>{t("carType")}</Form.Label>
                              <ul className="booking-radio-btns">
                                <li>
                                  <label className="booking_custom_check">
                                    <input
                                      type="radio"
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setCarTypeId(
                                          parseInt(event.target.value)
                                        );
                                      }}
                                      disabled={
                                        passengerNum >= 4 || luggNum >= 4
                                      }
                                      name="carType"
                                      value={CAR_TYPE_SEDAN}
                                      checked={carTypeId === CAR_TYPE_SEDAN}
                                      required
                                    />
                                    <span className="booking_checkmark">
                                      <span className="checked-title">
                                        {t("fourSeatSedan")}
                                      </span>
                                    </span>
                                  </label>
                                </li>
                                <li>
                                  <label className="booking_custom_check">
                                    <input
                                      type="radio"
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setCarTypeId(
                                          parseInt(event.target.value)
                                        );
                                      }}
                                      name="carType"
                                      value={CAR_TYPE_MPV}
                                      checked={carTypeId === CAR_TYPE_MPV}
                                      required
                                    />
                                    <span className="booking_checkmark">
                                      <span className="checked-title">
                                        {t("sixPassengerMPV")}
                                      </span>
                                    </span>
                                  </label>
                                </li>
                              </ul>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12}>
                            <Form.Group
                              className="input-block"
                              controlId="notes"
                            >
                              <Form.Label>{t("passengerRemark")}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("passengerRemark")}
                                value={note}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setNote(event.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="booking-info-btns d-none d-md-flex">
                      <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        delay={3000}
                        autohide
                      >
                        <Toast.Body>{toastMessage}</Toast.Body>
                      </Toast>
                      <button type="submit" className="btn btn-primary w-100">
                        {t("submitTrip")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 theiaStickySidebar">
                  <div className="booking-sidebar">
                    <div className="booking-sidebar-card">
                      <div className="booking-sidebar-head">
                        <h5>{t("costTitle")}</h5>
                      </div>
                      <div className="booking-sidebar-body">
                        <ul className="location-address-info">
                          <li>
                            <h6>
                              {t("zoneA")}- {t("originalPrice")}{" "}
                              <span className="text-danger text-decoration-line-through">
                                $580
                              </span>{" "}
                              - {t("memberOffer")} $380
                            </h6>
                            <p>{t("zoneADistricts")}</p>
                          </li>
                          <li>
                            <h6>
                              {t("zoneB")}- {t("originalPrice")}{" "}
                              <span className="text-danger text-decoration-line-through">
                                $630
                              </span>{" "}
                              - {t("memberOffer")} $430
                            </h6>
                            <p>{t("zoneBDistricts")}</p>
                          </li>
                          <li>
                            <h6>
                              {t("zoneC")}- {t("originalPrice")}{" "}
                              <span className="text-danger text-decoration-line-through">
                                $680
                              </span>{" "}
                              - {t("memberOffer")} $480
                            </h6>
                            <p>{t("zoneCDistricts")}</p>
                          </li>
                          <li>
                            <h6>{t("costRemarks")}</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <FareComponent
                      locationFrom={locationFrom}
                      locationTo={locationTo}
                      startTime={startTime}
                      districtFrom={districtFrom}
                      districtTo={districtTo}
                      orderTypeId={orderTypeId}
                      carTypeId={carTypeId}
                      luggNum={luggNum}
                      waitAtId={waitAtId}
                      onFareChange={onFareChange}
                    />
                    <div className="booking-info-btns d-flex d-md-none">
                      <Toast
                        onClose={() => setShowToast(false)}
                        show={showToast}
                        delay={3000}
                        autohide
                      >
                        <Toast.Body>{toastMessage}</Toast.Body>
                      </Toast>
                      <button type="submit" className="btn btn-primary w-100">
                        {t("submitTrip")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
